import { Injectable } from '@angular/core';
import { FiltersDataService } from '@data-services/filters.data-service';
import { FilterParameter } from '@shared/dto/filter.dto';
import { BehaviorSubject, Observable, Subject, debounceTime, finalize } from 'rxjs';
import { FilterFormHelper } from 'src/app/helpers/filter-form.helper';

@Injectable()
export class FiltersService {
  private _currentFilters$ = new Subject<{ brands: string[]; geographicAreas: string[] }>();
  get currentFilters$(): Observable<{ brands: string[]; geographicAreas: string[] }> {
    return this._currentFilters$.asObservable();
  }

  private _brands$ = new BehaviorSubject<FilterParameter[]>([]);
  get brands$(): Observable<FilterParameter[]> {
    return this._brands$.asObservable();
  }

  private _geoAreas$ = new BehaviorSubject<FilterParameter[]>([]);
  get geoareas$(): Observable<FilterParameter[]> {
    return this._geoAreas$.asObservable();
  }

  private _fileTypes$ = new BehaviorSubject<FilterParameter[]>([]);
  get fileTypes$(): Observable<FilterParameter[]> {
    return this._fileTypes$.asObservable();
  }

  private _adminBrands$ = new BehaviorSubject<FilterParameter[]>([]);
  get adminBrands$(): Observable<FilterParameter[]> {
    return this._adminBrands$.asObservable();
  }

  private _adminGeoAreas$ = new BehaviorSubject<FilterParameter[]>([]);
  get adminGeoareas$(): Observable<FilterParameter[]> {
    return this._adminGeoAreas$.asObservable();
  }

  private _adminFileTypes$ = new BehaviorSubject<FilterParameter[]>([]);
  get adminFileTypes$(): Observable<FilterParameter[]> {
    return this._adminFileTypes$.asObservable();
  }

  isLoading$ = new BehaviorSubject<boolean>(false);
  isSpinLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private _filtersDataService: FiltersDataService, private _formHelper: FilterFormHelper) {}

  init(): void {
    this._getFilters('brands', this._brands$);
    this._getFilters('geoareas', this._geoAreas$);
    this._getFilters('filetypes', this._fileTypes$);
    this.updateFilters();
  }

  initAdminFilters(): void {
    this._getAdminFilters('brands', this._adminBrands$);
    this._getAdminFilters('geoareas', this._adminGeoAreas$);
    this._getAdminFilters('filetypes', this._adminFileTypes$);
  }

  updateFilters(): void {
    console.log('va');

    this._formHelper.form
      .get('geographicAreas')
      ?.valueChanges.pipe(debounceTime(750))
      .subscribe((_) => {
        console.log(this._formHelper.currentFilterDto);

        this._currentFilters$.next(this._formHelper.currentFilterDto);
      });

    this._formHelper.form
      .get('brands')
      ?.valueChanges.pipe(debounceTime(750))
      .subscribe((_) => {
        // this._currentFilters$.next(this._formHelper.currentFilterDto);
      });
  }

  private _getFilters(
    filterType: 'brands' | 'geoareas' | 'filetypes',
    subject: BehaviorSubject<{ code: string; label: string }[]>
  ): void {
    this.isLoading$.next(true);

    this._filtersDataService
      .getChipFilters(filterType)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe({
        next: (res) => {
          subject.next(res.data);
        },
        error: (err) => {
          console.error('An error occurred:', err);
          // Handle the error appropriately, such as displaying an error message.
        },
      });
  }

  private _getAdminFilters(
    filterType: 'brands' | 'geoareas' | 'filetypes',
    subject: BehaviorSubject<FilterParameter[]>
  ): void {
    this.isLoading$.next(true);

    this._filtersDataService
      .getAdminFilters(filterType)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe({
        next: (res) => {
          subject.next(res.data);
        },
        error: (err) => {
          console.error('An error occurred:', err);
          // Handle the error appropriately, such as displaying an error message.
        },
      });
  }
}
