import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { RtLoadingComponent } from '@shared/components/rt-loading/rt-loading.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from 'src/app/auth/config/auth-code-flow.config';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@shared/services/storage.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'cdz-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterOutlet, SidebarComponent, RtLoadingComponent],
})
export class AppComponent {
  constructor(
    private oauthService: OAuthService,
    private _usersService: UsersService,
    public translate: TranslateService,
    private _storageService: StorageService
  ) {
    this.configureOAuth();
    translate.addLangs(['en', 'it']);

    const lang: string | null = _storageService.getLangPref();
    translate.use(lang || 'it');
  }

  private configureOAuth(): void {
    this.oauthService.configure(authCodeFlowConfig);
    // Set the token validation handler to JwksValidationHandler
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.oauthService.hasValidAccessToken()) {
        this._usersService.getMe();
        this.oauthService.setupAutomaticSilentRefresh(); // Enable automatic token refreshing
        return;
      }

      this.oauthService.initCodeFlow();
    });
  }
}
