import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { ApiPaginatedResponse } from '@shared/dto/base-res.dto';
import { StoreFilterBody } from '@shared/dto/filter.dto';
import { GeoareaDto, MapStore, RegionsDto, ListStore, StoreDetails } from '@shared/dto/store.dto';

@Injectable({ providedIn: 'root' })
export class StoresDataService {
  protected currentUrl: string = environment.apiUri + 'stores';

  constructor(private _http: HttpClient) {}

  getStore(id: string): Observable<ApiPaginatedResponse<StoreDetails>> {
    return this._http.get(this.currentUrl + `/${id}`) as Observable<ApiPaginatedResponse<StoreDetails>>;
  }

  getStoresPage(
    page: number,
    pageSize: number,
    filterBody: StoreFilterBody
  ): Observable<ApiPaginatedResponse<ListStore[]>> {
    const params = new HttpParams().set('pageSize', pageSize).set('page', page);
    return this._http.post<ApiPaginatedResponse<any[]>>(this.currentUrl + '/page', { filters: filterBody }, { params });
  }

  getMapStores(
    page: number,
    pageSize: number,
    latLan: google.maps.LatLng | undefined,
    filterBody: StoreFilterBody
  ): Observable<ApiPaginatedResponse<MapStore[]>> {
    if (!latLan) {
      throw new Error('missing LatLan in api Call');
    }
    const params = new HttpParams()
      .set('pageSize', pageSize)
      .set('page', page)
      .set('center', `${latLan.lat()},${latLan.lng()}`);
    return this._http.post<ApiPaginatedResponse<any[]>>(this.currentUrl, { filters: filterBody }, { params });
  }

  getGeoareas(filterBody: StoreFilterBody): Observable<ApiPaginatedResponse<GeoareaDto[]>> {
    return this._http.post<ApiPaginatedResponse<any[]>>(this.currentUrl + '/aggregation/geoareas', {
      filters: filterBody,
    });
  }

  getRegions(filterBody: StoreFilterBody): Observable<ApiPaginatedResponse<RegionsDto[]>> {
    return this._http.post<ApiPaginatedResponse<any[]>>(this.currentUrl + '/aggregation/regions', {
      filters: filterBody,
    });
  }
}
