<div class="-wrapper-shops">
  <cdz-store-filter [@slideInOut]="menuState()"></cdz-store-filter>
  <div
    class="-container-list"
    [@slide]="menuState()">
    <div class="-container-title py-9 px-3 flex items-center justify-between">
      <div class="flex items-center gap-2">
        <div class="-title headline-5"> {{ 'shops.title' | translate }} </div>
        <button
          mat-flat-button
          (click)="toggleMenu()"
          class="-filter">
          <mat-icon>tune</mat-icon>
        </button>
      </div>
      <div class="-container-split">
        <div
          (click)="toggleView()"
          class="-split-grid rounded-l-lg"
          [class.-active]="isGridView() === true">
          <mat-icon>grid_view</mat-icon>
        </div>
        <div
          (click)="toggleView()"
          class="-split-screen rounded-r-lg"
          [class.-active]="isGridView() === false">
          <mat-icon>splitscreen</mat-icon>
        </div>
      </div>
    </div>
    <div class="-container-search flex items-center justify-between mb-12 gap-5">
      <div class="-search-box flex-1">
        <button class="-search-btn"> <mat-icon>search</mat-icon> </button>
        <input
          class="-search-input"
          type="text"
          placeholder="{{ 'shops.searchLabel' | translate }}" />
      </div>
      <button
        mat-flat-button
        class="-filter">
        <mat-icon>tune</mat-icon>
      </button>
    </div>
    <div
      class="-container-shops"
      [class.-grid-view]="isGridView() === true"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="100"
      (scrolled)="onScroll()">
      <ng-container *ngIf="stores().length; else noStore">
        <cdz-store
          *ngFor="let store of stores()"
          [isGridView]="isGridView()"
          [store]="store"></cdz-store>
      </ng-container>
      <ng-template #noStore>
        <p class="-no-shop caption py-5 px-3">Nessun negozio presente</p>
      </ng-template>
    </div>
    <p
      *ngIf="stores().length < totalItems()"
      class="py-5">
      <mat-spinner
        class="m-auto"
        [diameter]="40"
        [strokeWidth]="2"
        color="primary"></mat-spinner>
    </p>
  </div>
</div>
