<div
  class="-container-profile body-2 flex justify-between items-center"
  [class.-sidebar]="isSidebar">
  <div class="flex items-center gap-1">
    <button [matMenuTriggerFor]="menuLang">
      <mat-icon [class.text-white]="isSidebar">language</mat-icon>
    </button>
    <p
      class="caption mb-1"
      [class.text-white]="isSidebar">
      {{ translate.currentLang }} -
    </p>
    <img
      alt="flag"
      class="w-4 mb-1"
      [src]="'assets/images/' + translate.currentLang + '.svg'" />
  </div>
  <mat-menu #menuLang="matMenu">
    <button
      mat-menu-item
      *ngFor="let language of translate.getLangs()"
      (click)="switchLang(language)">
      <div class="flex items-center gap-3">
        <img
          alt="flag"
          class="w-5"
          [src]="'assets/images/' + language + '.svg'" />
        <span class="language-name">{{ language }}</span>
      </div>
    </button>
  </mat-menu>

  <div>
    <button [matMenuTriggerFor]="menu">
      <div
        class="-profile"
        [class.-sidebar]="isSidebar">
        <img
          *ngIf="false"
          alt="profile" />
        <span class="caption">
          {{ (user$ | async)?.firstName | slice : 0 : 1 }} {{ (user$ | async)?.lastName | slice : 0 : 1 }}
        </span>
      </div>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="refreshProfile()">
      <mat-icon>refresh</mat-icon>
      <span>Aggiorna Profilo</span>
    </button>
    <button
      mat-menu-item
      (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</div>
