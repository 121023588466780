import { Routes } from '@angular/router';
import { UserAuthGuard } from 'src/app/auth/guards/user-auth.guard';
import { DashboardPage } from 'src/app/pages/dashboard/dashboard.page';
import { UsersPage } from 'src/app/pages/users/users.page';
import { StoresPage } from 'src/app/pages/stores/stores.page';
import { MapPage } from 'src/app/pages/map/map.page';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./pages/dashboard/dashboard.page').then(() => DashboardPage),
  },
  {
    path: 'location',
    loadComponent: () => import('./pages/map/map.page').then(() => MapPage),
    canActivate: [UserAuthGuard],
  },
  {
    path: 'users',
    loadComponent: () => import('./pages/users/users.page').then(() => UsersPage),
    canActivate: [UserAuthGuard],
  },
  {
    path: 'stores',
    loadComponent: () => import('./pages/stores/stores.page').then(() => StoresPage),
    canActivate: [UserAuthGuard],
  },
];
