import { ISidebarLink } from '../components/sidebar/sidebar.component';

export const linksMenu: ISidebarLink[] = [
  {
    iconName: 'home',
    labelIt: 'Home',
    labelEn: 'Home',
    menuKey: 'DASHBOARD',
    routerLinkBase: '/dashboard',
    handlingStrategy: '',
  },
  {
    iconName: 'location_on',
    labelIt: 'Mappa',
    labelEn: 'Map',
    menuKey: 'LOCATION',
    routerLinkBase: '/location',
    handlingStrategy: '',
  },
  {
    iconName: 'store',
    labelIt: 'Negozi',
    labelEn: 'Stores',
    menuKey: 'SHOPS',
    routerLinkBase: '/stores',
    handlingStrategy: 'preserve',
  },
  {
    iconName: 'manage_accounts',
    labelIt: 'Utenti',
    labelEn: 'Users',
    menuKey: 'USERS',
    routerLinkBase: '/users',
    handlingStrategy: '',
  },
];
