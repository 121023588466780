/**
 * A custom data structure that allows for easy access of the current, previous and next state (item) of an array.
 */
export class statefulArray<arrayType> {
  #arr: arrayType[];
  #currentIndex: number;
  #length: number;

  constructor(inputArray: arrayType[], start: number = 0) {
    this.#arr = inputArray;
    this.#currentIndex = start;
    this.#length = this.#arr.length;
  }

  /**
   * Get length of the original array.
   */
  get length(): number {
    return this.#length;
  }

  /**
   * Get a copy of the original array.
   */
  get me(): arrayType[] {
    return [...this.#arr];
  }

  /**
   * Return whether or not the current state is the last in the array.
   */
  isLast(): boolean {
    return this.#currentIndex == this.#length - 1;
  }

  /**
   * Peek the next item in the array. Return null if there's no next item.
   *
   * This DOES NOT update the current state and is used to make evaluations
   * based on the next entry in the array.
   */
  peek(): arrayType | null {
    if (this.#currentIndex + 1) {
      return this.#arr[this.#currentIndex + 1];
    } else {
      return null;
    }
  }

  /**
   * Look behind to the previous item in the array. Return null if there's no previous item.
   *
   * This DOES NOT update the current state and is used to make evaluations
   * based on the previous entry in the array.
   */
  lookBehind(): arrayType | null {
    if (this.#currentIndex - 1 >= 0) {
      return this.#arr[this.#currentIndex - 1];
    } else {
      return null;
    }
  }

  /**
   * Get the current item of the array.
   */
  get current(): arrayType {
    return this.#arr[this.#currentIndex];
  }

  /**
   * Get the next item of the array and update the current state to the next item.
   * If the next item is out of bounds, return null.
   */
  get next(): arrayType | null {
    if (this.#currentIndex + 1 < this.#length) {
      return this.#arr[++this.#currentIndex];
    } else {
      return null;
    }
  }
  /**
   * Get the previous item of the array and update the current state to the previous item.
   * If the previous item is out of bounds, return null.
   */
  get previous(): arrayType | null {
    {
      if (this.#currentIndex - 1 >= 0) {
        return this.#arr[--this.#currentIndex];
      } else {
        return null;
      }
    }
  }
}

