<div class="-sidebar h-full">
  <div class="flex flex-col items-center justify-between h-full">
    <div class="-container-logo">
      <img
        class="w-full"
        src="assets/images/cdz-logo.png"
        alt="logo" />
    </div>
    <div class="-container-link">
      <ul>
        <li
          *ngFor="let link of linksMenu; let i = index"
          [routerLink]="link.routerLinkBase"
          [queryParamsHandling]="link.handlingStrategy"
          routerLinkActive="-active"
          class="flex flex-col items-center gap-1 px-5 py-3 rounded-full cursor-pointer">
          <mat-icon> {{ link.iconName }} </mat-icon>
          <a class="caption">
            {{ translate.currentLang === 'it' ? link.labelIt : link.labelEn }}
          </a>
        </li>
      </ul>
    </div>
    <div class="-container-profile">
      <cdz-profile [isSidebar]="true"></cdz-profile>
    </div>
  </div>
</div>
