import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://sso.addvalue.it/auth/realms/czd',
  redirectUri: window.location.origin + '/dashboard',
  clientId: 'czd-dev',
  responseType: 'code',
  scope: 'profile email roles',
  showDebugInformation: true,
};
