<div class="-user">
  <div class="-container-profile">
    <div class="-profile rounded text-center">
      <!-- <img
        alt="profile"
        class="rounded"
        *ngIf="user?.image"
        [src]="user?.image" /> -->
      <span class="body-2"> {{ user?.firstName | slice : 0 : 1 }} {{ user?.lastName | slice : 0 : 1 }} </span>
    </div>
    <div class="-name subtitle-2"> {{ user?.firstName }} {{ user?.lastName }} </div>
  </div>
  <div class="-container-actions">
    <!-- <div class="-label caption"> {{ user?.companyRole }} </div> -->
    <div class="-chip caption"> {{ user?.role | titlecase }} </div>
    <div
      class="-chip caption"
      *ngFor="let geographicArea of user?.permissions?.geographicAreas">
      {{ geographicArea }}
    </div>
    <button (click)="editUser.emit(user)">
      <mat-icon class="leading-8">edit</mat-icon>
    </button>
  </div>
</div>
