import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Self } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RtDialogTemplateComponent } from '@shared/components/rt-dialog-template/rt-dialog-template.component';
import { UserReadDto } from '@shared/dto/user.dto';
import { RT_DIALOG_CLOSE_RESULT } from '@shared/enums/rt-dialog-close-result.enum';
import { IRtDialogInput } from '@shared/interfaces/i-rt-dialog-input.interface';
import { UserFormHelper } from 'src/app/helpers/user-form.helper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Observable, finalize } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.service';
import { FiltersService } from 'src/app/services/filters.service';
import { UsersDataService } from 'src/app/data/services/users.data-service';
import { FilterParameter } from '@shared/dto/filter.dto';

@Component({
  selector: 'cdz-edit-user',
  standalone: true,
  templateUrl: './edit-user.dialog.html',
  styleUrls: ['./edit-user.dialog.scss'],
  imports: [
    RtDialogTemplateComponent,
    MatDialogModule,
    MatIconModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
  ],
  providers: [UserFormHelper, FiltersService],
})
export class EditUserDialog implements OnInit {
  selectedUser?: UserReadDto;
  isLoading: boolean = false;
  hasErrors: boolean = false;
  showActionBtns: boolean = true;
  user: UserReadDto = {
    id: 1,
    idpId: '',
    firstName: '',
    lastName: '',
    role: 'ADMIN',
    email: '',
    permissions: {
      geographicAreas: [''],
      brands: [''],
      filesAllowed: [''],
    },
  }; // FIXME: Added to stop compilation errors; user is present in the html

  adminBrands$?: Observable<FilterParameter[]>;
  adminGeoareas$?: Observable<FilterParameter[]>;
  adminFiletypes$?: Observable<FilterParameter[]>;

  constructor(
    protected _fb: FormBuilder,
    protected _matDialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IRtDialogInput<UserReadDto>,
    @Self() public formHelper: UserFormHelper,
    private _usersService: UsersService,
    private _usersDataService: UsersDataService,
    private _filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this.selectedUser = this.data.input;
    this.formHelper.patchForm(this.selectedUser);

    // Check if user is admin
    this._usersService.currentUser$.subscribe({
      next: (loggedUser: UserReadDto | null) => {
        // FIXME: checking for admins is redundant?
        // can normal users get here?
        if (loggedUser?.id === this.selectedUser?.id && this.selectedUser?.role === 'ADMIN') {
          this.formHelper.disable();
          this.showActionBtns = false;
        }
      },
    });

    // Get All Filters
    this._filtersService.initAdminFilters();

    this.adminBrands$ = this._filtersService.adminBrands$;
    this.adminGeoareas$ = this._filtersService.adminGeoareas$;
    this.adminFiletypes$ = this._filtersService.adminFileTypes$;
  }

  onSubmit(): void {
    this._updateProfile();
  }

  /**
   * Default "Cancel" action.
   */
  cancel(): void {
    this._matDialogRef.close({
      result: RT_DIALOG_CLOSE_RESULT.CANCEL,
    });
  }

  private _updateProfile(): void {
    if (!this.selectedUser?.id) {
      return;
    }

    this.isLoading = true;

    this._usersDataService
      .updateProfile(this.selectedUser?.id, this.formHelper.updateDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          // TODO: Test me on the frontend
          // return updatedUser to update frontend
          this._matDialogRef.close({
            result: RT_DIALOG_CLOSE_RESULT.CONFIRM,
            data: { updatedUser: this.selectedUser },
          });
        },
        error: () => {
          this.hasErrors = true;
        },
      });
  }
}
