import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserReadDto } from '@shared/dto/user.dto';
import { StorageService } from '@shared/services/storage.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'cdz-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [CommonModule, MatIconModule, RouterModule, MatMenuModule],
})
export class ProfileComponent implements OnInit {
  user$?: Observable<UserReadDto | null>;
  @Input() isSidebar: boolean = false;

  constructor(
    private _usersService: UsersService,
    public translate: TranslateService,
    private _oauthService: OAuthService,
    private _storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.user$ = this._usersService.currentUser$;
  }

  refreshProfile(): void {
    this._usersService.getMe();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this._storageService.saveLangPref(lang);
  }

  logout(): void {
    this._oauthService.logOut();
  }
}
