import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileComponent } from '@shared/components/profile/profile.component';

@Component({
  selector: 'cdz-dashboard',
  standalone: true,
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
  imports: [CommonModule, MatMenuModule, MatIconModule, RouterModule, ProfileComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPage {}
