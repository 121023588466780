import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RtDialogDefaultActionsComponent } from '@shared/components/rt-dialog-default-actions/rt-dialog-default-actions.component';
import { RtDialogService } from '@shared/services/rt-dialog.service';

@Component({
  selector: 'cdz-dialog-template',
  standalone: true,
  templateUrl: './rt-dialog-template.component.html',
  styleUrls: ['./rt-dialog-template.component.scss'],
  imports: [RtDialogDefaultActionsComponent, MatIconModule, MatProgressBarModule, CommonModule, MatDialogModule],
  providers: [RtDialogService],
})
export class RtDialogTemplateComponent {
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  @Input() cTitle: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() isFooterEnabled: boolean = true;
  @Input() isHeaderEnabled: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() isError: boolean = false;
  @Input() errorMsg?: string = 'Si è verificato un errore. Riprova';
  @Input() hasClose: boolean = false;
}
