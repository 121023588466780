import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '@shared/classes/form-helper.class';

@Injectable({
  providedIn: 'root',
})
export class FilterFormHelper extends FormHelper<any> {
  constructor(fb: FormBuilder) {
    super(fb);
  }

  initForm(): FormGroup<any> {
    const form: FormGroup = this.fb.group({
      geographicAreas: [null],
      brands: [null],
    });

    return form;
  }

  patchForm(value: any): void {
    this.form.patchValue(value);
  }

  get currentFilterDto(): any {
    const formValues: any = this.form.getRawValue();

    return {
      ...formValues,
    };
  }
}
