import { Injectable } from '@angular/core';
import { StoresDataService } from '@data-services/stores.data-service';
import { ApiPaginatedResponse } from '@shared/dto/base-res.dto';
import { StoreFilterBody } from '@shared/dto/filter.dto';
import { ListStore, StoreDetails } from '@shared/dto/store.dto';
import { RtLoadingService } from '@shared/services/rt-loading.service';
import { BehaviorSubject, delay, finalize, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  private _stores$ = new Subject<ListStore[]>();
  get stores$(): Observable<ListStore[]> {
    return this._stores$.asObservable();
  }

  private _totalItems$ = new BehaviorSubject<number>(0);
  get totalItems$(): Observable<number> {
    return this._totalItems$.asObservable();
  }

  constructor(private _rtLoadingService: RtLoadingService, private _storesDataService: StoresDataService) {}

  getStores(page: number, pageSize: number, filterBody: StoreFilterBody, isScrolling: boolean = false): void {
    if (!isScrolling) {
      this._rtLoadingService.showLoadingBlocking();
    }

    this._storesDataService
      .getStoresPage(page, pageSize, filterBody)
      .pipe(
        delay(1000),
        finalize(() => this._rtLoadingService.hideLoadingBlocking())
      )
      .subscribe({
        next: (res: ApiPaginatedResponse<ListStore[]>) => {
          this._stores$.next(res.data);
          this._totalItems$.next(res.pagination.totalItems ?? 0);
        },
        error: () => {
          // TODO: Capire come gestire l'errore
        },
      });
  }
}
