import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { ApiResponse } from '@shared/dto/base-res.dto';
import { FilterParameter } from '@shared/dto/filter.dto';

@Injectable({ providedIn: 'root' })
export class FiltersDataService {
  protected currentUrl: string = environment.apiUri + 'filters';
  protected currentAdminUrl: string = environment.apiAdminUri + 'filters';

  constructor(private _http: HttpClient) {}

  getChipFilters(path: 'brands' | 'geoareas' | 'filetypes'): Observable<ApiResponse<FilterParameter[]>> {
    return this._http.get<ApiResponse<{ code: string; label: string }[]>>(this.currentUrl + '/' + path);
  }

  getAdminFilters(path: 'brands' | 'geoareas' | 'filetypes'): Observable<ApiResponse<FilterParameter[]>> {
    return this._http.get<ApiResponse<{ code: string; label: string }[]>>(this.currentAdminUrl + '/' + path);
  }
}
