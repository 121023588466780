import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Signal, WritableSignal, signal } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RtDialogService } from '@shared/services/rt-dialog.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StoreComponent } from '@shared/components/store/store.component';
import { StorageService } from '@shared/services/storage.service';
import { TranslateModule } from '@ngx-translate/core';
import { StoreFilterComponent } from '@shared/components/store-filter/store-filter.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { StoresService } from 'src/app/services/stores.service';
import { ListStore } from '@shared/dto/store.dto';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'cdz-stores',
  standalone: true,
  templateUrl: './stores.page.html',
  styleUrls: ['./stores.page.scss'],
  imports: [
    MatIconModule,
    StoreComponent,
    CommonModule,
    MatDialogModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    TranslateModule,
    StoreFilterComponent,
  ],
  providers: [RtDialogService, FiltersService],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(-100%, 0, 0)',
          flex: 0,
          padding: 0,
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
    trigger('slide', [
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoresPage implements OnInit {
  stores = signal<ListStore[]>([]);
  totalItems = signal<number>(0);
  page: WritableSignal<number> = signal(0);
  pageSize: Signal<number> = signal(25);
  showSpinScrolling: WritableSignal<boolean> = signal(false);
  // TODO: -> refactor
  filterBody: any;

  isGridView: WritableSignal<boolean> = signal(true);
  menuState: WritableSignal<string> = signal('out');

  constructor(
    private _storageService: StorageService,
    private _storesService: StoresService,
    private _filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this._storesService.stores$.subscribe({
      next: (stores: ListStore[]) => {
        this.stores.mutate((prevStores) => {
          for (const store of stores) {
            prevStores.push(store);
          }
        });
        this.showSpinScrolling.set(false);
      },
    });

    this._filtersService.currentFilters$.subscribe((filterBody: any) => {
      this.filterBody = filterBody;
      this._storesService.getStores(this.page(), this.pageSize(), filterBody);
    });

    // TODO: Higher level fun refactor -> also in Users Page
    this._storesService.totalItems$.subscribe({
      next: (totalItems: number) => {
        this.totalItems.set(totalItems);
      },
    });

    // Save Pref
    this.isGridView.set(this._storageService.getViewPref() === 'grid' ? true : false);
  }

  toggleMenu() {
    this.menuState.update((val) => (val === 'out' ? 'in' : 'out'));
  }

  toggleView(): void {
    this.isGridView.update((value) => !value);
    this._storageService.saveViewPref(this.isGridView() ? 'grid' : 'splitscreen');
  }

  // TODO: Refactor?
  // openFilterDialog(): void {
  //   this._rtDialogService
  //     .open(FilterDialog, { width: '35vw', height: '90vh', position: { right: '2%' } })
  //     .subscribe((res) => {
  //       if (res.result === RT_DIALOG_CLOSE_RESULT.CONFIRM) {
  //         //
  //       }
  //     });
  // }

  onScroll() {
    if (this.stores().length < this.totalItems()) {
      this.showSpinScrolling.set(true);
      this.page.set(this.page() + 1);
      this._storesService.getStores(this.page(), this.pageSize(), this.filterBody, true);
    }
  }
}
