import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { QueryParamsHandling, RouterModule } from '@angular/router';
import { ProfileComponent } from '@shared/components/profile/profile.component';
import { UserReadDto } from '@shared/dto/user.dto';
import { linksMenu } from '../../config/i-sidebar-config';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.service';

export interface ISidebarLink {
  iconName: string;
  labelIt: string;
  labelEn: string;
  menuKey: string;
  routerLinkBase: string;
  handlingStrategy: QueryParamsHandling;
}

@Component({
  selector: 'cdz-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [CommonModule, MatIconModule, RouterModule, ProfileComponent, TranslateModule],
})
export class SidebarComponent implements OnInit {
  linksMenu: ISidebarLink[] = linksMenu;
  isAdmin: boolean = false;

  constructor(private _usersService: UsersService, public translate: TranslateService) {}

  ngOnInit(): void {
    this._usersService.currentUser$.subscribe({
      next: (user: UserReadDto | null) => {
        if (!user) {
          return;
        }

        this.isAdmin = user.role.toUpperCase() === 'ADMIN'; // ! Egde case
      },
    });
  }
}
