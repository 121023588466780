<mat-progress-bar
  mode="indeterminate"
  color="accent"
  *ngIf="isLoading"></mat-progress-bar>

<div
  class="-error"
  *ngIf="isError && !isLoading"
  >{{ errorMsg }}</div
>

<div
  class="-header"
  *ngIf="isHeaderEnabled">
  <div>
    <span
      *ngIf="cTitle"
      class="-title"
      >{{ cTitle }}</span
    >
  </div>

  <button
    (click)="closeDialog.emit()"
    mat-icon-button
    type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<main class="-main">
  <div
    class="body1 py-3 --fw500"
    *ngIf="subtitle"
    [innerHTML]="subtitle">
  </div>

  <div
    [class.-loading-layer]="isLoading"
    class="-container-body">
    <div mat-dialog-content>
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</main>

<div
  mat-dialog-actions
  class="-footer"
  *ngIf="isFooterEnabled && !isError">
  <ng-content select="[footer]"></ng-content>
</div>
