<div class="-filter-box">
  <div class="-container-main">
    <div class="-search-box mb-5">
      <button class="-search-btn"> <mat-icon>search</mat-icon> </button>
      <input
        class="-search-input"
        type="text"
        placeholder="{{ 'shops.searchLabel' | translate }}" />
    </div>
    <div class="mb-2">
      <span class="body-2"> {{ 'shops.filter' | translate }} </span>
    </div>
    <form
      [formGroup]="formHelper.form"
      class="mb-5">
      <div class="-filter-block">
        <div class="-label body-2 mb-3"> {{ 'shops.continent' | translate }}</div>
        <mat-chip-listbox
          multiple
          formControlName="geographicAreas">
          <mat-chip-option
            *ngFor="let area of geoAreas$ | async"
            [selected]="areaSelected(area)"
            [value]="area.code">
            {{ area.label | titlecase }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- <div class="-filter-block">
        <div class="-label body-2 mb-3"> Fatturato</div>
        <mat-slider
          min="200"
          max="500"
          color="primary">
          <input
            value="300"
            matSliderStartThumb />
          <input
            value="400"
            matSliderEndThumb />
        </mat-slider>
      </div> -->
      <div class="-filter-block">
        <div class="-label body-2 mb-3"> Brand</div>
        <mat-chip-listbox
          multiple
          formControlName="brands">
          <mat-chip-option
            *ngFor="let brand of brands$ | async"
            [value]="brand.code"
            [selected]="brandSelected(brand)">
            {{ brand.label | titlecase }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </form>
    <div class="-container-actions flex items-center justify-between px-6">
      <div class="-reset body-2"> Reset {{ 'shops.filter' | translate | lowercase }} </div>
      <button class="-btn-results">
        <span> {{ 'shops.show' | translate }} 3 {{ 'shops.results' | translate }}</span>
      </button>
    </div>
  </div>
</div>
