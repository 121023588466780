<div class="-dashboard">
  <cdz-profile></cdz-profile>
  <div class="-container-logo mb-6 mt-8">
    <img
      src="assets/images/cdz-logo.png"
      alt="logo" />
  </div>
  <div class="-container-main-info">
    <div class="-title headline-4 mb-2"> {{ 'dashboard.title' | translate }} </div>
    <div class="-subtitle body-2 opacity-40">
      Sandro Veronesi <br />
      {{ 'dashboard.subtitle' | translate }}
    </div>
  </div>
  <div class="-container-card mb-10">
    <div class="-card">
      <div class="-title headline-6"> {{ 'dashboard.cardMap' | translate }} </div>
      <div class="-container-img">
        <img
          src="assets/images/dashboard-map.png"
          alt="map" />
      </div>
      <button
        routerLink="/location"
        class="-cta body-2">
        {{ 'dashboard.cardCta' | translate }}
      </button>
    </div>
    <div class="-card">
      <div class="-title headline-6"> {{ 'dashboard.cardShop' | translate }} </div>
      <div class="-container-img">
        <img
          src="assets/images/dashboard-list.png"
          alt="list" />
      </div>
      <button
        class="-cta body-2"
        routerLink="/shops">
        {{ 'dashboard.cardCta' | translate }}
      </button>
    </div>
  </div>
  <div class="-container-sub-info body-2 text-center opacity-40">
    © Calzedonia S.p.A Via Monte Baldo n. 20, 37062, Dossobuono di Villafranca (VR), Italia - P.iva 02253210237
  </div>
</div>
