import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '@shared/classes/form-helper.class';
import { UserReadDto, UserUpdateDto } from '@shared/dto/user.dto';

@Injectable()
export class UserFormHelper extends FormHelper<UserReadDto> {
  constructor(fb: FormBuilder) {
    super(fb);
  }

  initForm(): FormGroup<any> {
    const form: FormGroup = this.fb.group({
      role: [null],
      geographicAreas: [null],
      brands: [null],
      filesAllowed: [null],
    });

    return form;
  }

  patchForm(value: UserReadDto): void {
    this.form.patchValue({
      role: value.role,
      ...value.permissions,
    });
  }

  get updateDto(): UserUpdateDto {
    const formValues: any = this.form.getRawValue();

    return {
      role: formValues.role,
      permissions: {
        geographicAreas: formValues.geographicAreas,
        brands: formValues.brands,
        filesAllowed: formValues.filesAllowed,
      },
    };
  }

  compareFn(c1: string, c2: string): boolean {
    if (c1 && c2 && c1.trim() !== '' && c2.trim() !== '') {
      return c1 === c2;
    }

    return false;
  }
}
