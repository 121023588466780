<div
  class="-shop"
  [class.-split-screen]="isGridView === false">
  <div class="-container-img">
    <img
      [class.-img-split-screen]="isGridView === false"
      alt="shop"
      src="{{ store?.images !== null ? (store?.images)![0] : 'https://placehold.co/600x400' }}" />
  </div>
  <div class="-container-info">
    <div>
      <div class="-name headline-6"> {{ store?.name | titlecase }} </div>
      <div class="-info caption">
        {{ store?.country }} - {{ store?.region }} - {{ store?.address }}, {{ store?.city }}
      </div>
    </div>
    <div class="-container-chips mt-2">
      <div class="-chip caption">Diretto</div>
      <div class="-chip caption">Affiliato</div>
    </div>
  </div>
</div>
