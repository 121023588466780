import { Component, inject } from '@angular/core';
import { RtLoadingService } from '../../services/rt-loading.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'cdz-rt-loading',
  standalone: true,
  templateUrl: './rt-loading.component.html',
  styleUrls: ['./rt-loading.component.scss'],
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class RtLoadingComponent {
  loadingSvc = inject(RtLoadingService);
}
