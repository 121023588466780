import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, WritableSignal, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import '@data-services/stores.data-service';
import { StoresDataService } from '@data-services/stores.data-service';
import { TranslateModule } from '@ngx-translate/core';
import { StoreFilterComponent } from '@shared/components/store-filter/store-filter.component';
import { ApiPaginatedResponse } from '@shared/dto/base-res.dto';
import { StoreFilterBody } from '@shared/dto/filter.dto';
import { GeoareaDto, MapStore, RegionsDto, StoreDetails } from '@shared/dto/store.dto';
import { RtDialogService } from '@shared/services/rt-dialog.service';
import { Observable, take } from 'rxjs';
import { FiltersService } from 'src/app/services/filters.service';
import { MapStoreComponent } from '@shared/components/map-store/map-store.component';
import { statefulArray } from 'src/app/utils/data-structures';
import { NestedKeysOf } from 'src/app/utils/special-types';

// TODO: Move interfaces and types outside of this class
type RangesConfig = statefulArray<RangeConfig<GeoareaDto> | RangeConfig<RegionsDto> | RangeConfig<MapStore>>;

interface RangeConfig<T extends object> {
  /**
   * The zoom treshold after which this range starts.
   */
  minZoomValue: number;
  /**
   * The MarkerOptions template used to create all of the markers of this range.
   */
  markerOptionsTemplate: google.maps.MarkerOptions;
  /**
   * This property is used to swap properties in markerOptionsTemplate with the specified
   * T's propeties values and is used to get dynamically built markerOptions.
   *
   * Example: {label.text: labelText} will set MarkerOptions.label.text = T[labelText]
   */
  markerOptionsSwapMap: { [key in NestedKeysOf<google.maps.MarkerOptions>]?: keyof T } | null;
  /**
   * Function that gets mapped to the (mapClick) event of the markers of this range.
   */
  markerClickFunction: Function;
  /**
   * The observable that results from an api call, used to get MarkerOptions.position.
   */
  apiObservable: Observable<ApiPaginatedResponse<T[]>>;
}

// FIXME (remove me) FILE'S GENERAL TODO AND FIXME
// TODO: the getStore call from stores.data-service doesn't return the data necessary for correct pagination. Create the desired interface and ask for a migration.
// TODO: Add special marker on selected marker
// TODO: update shops on map movement

@Component({
  selector: 'cdz-map',
  standalone: true,
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
  imports: [
    CommonModule,
    GoogleMapsModule,
    MapStoreComponent,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule,
    StoreFilterComponent,
    TranslateModule,
  ],
  providers: [RtDialogService, FiltersService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Map page: show a map and a filter to its side. On the map, markers show the location of filtered stores.
 */
export class MapPage implements OnInit {
  @ViewChild('map') _map!: google.maps.Map;

  // FIXME: update with the real filter; should be working without tweaks
  currentFilter: StoreFilterBody = {
    brands: [],
    geographicAreas: [],
  };

  storesLatlngToStoreCode: { [latlng: string]: string } = {};

  /* FIXME: Store the store in a dumb way that won't cache the call.
  Could be made much better by using dictionary to store the cached stores for the session as {lat,lng: storeData};
  that is, if data invalidation is unlikely during one person's map session.
   */
  currentStore: WritableSignal<StoreDetails | null> = signal(null);

  /*
  Angular's Google maps component (https://github.com/angular/components), as of v16.1.5,
  uses the variable that sets the <google-maps> component's [zoom] option
  instead of the `setZoom` method provided by the original google maps api.
  https://github.com/angular/components/issues/23093

  The map component's zoom change events, however, do not update the variable used in [zoom]. This causes them to fall
  out of sync if the variable isn't updated manually.
  It's important to call `this.mapZoom = map.getZoom()` on zoom changed events to keep them in sync.
  */
  mapZoom: number = 2;
  currentMapMarkerPositions: WritableSignal<google.maps.LatLngLiteral[]> = signal([]);
  mapCenter: google.maps.LatLngLiteral = { lat: 45.4039001, lng: 11.8724676 };

  googleMapsOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    fullscreenControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    mapTypeControl: false,
    streetViewControl: false,
    mapId: '3ccc4166b086da4e',
  };

  mapMarkerOptions: google.maps.MarkerOptions = {};

  /**
   * Ranges config is a custom config object that is used to define
   * different google maps zooms' tresholds and how markers work in each of them.
   *
   * See the interface definition for information on each option.
   */
  rangesConfig?: RangesConfig;

  mapChanging: boolean = false;

  constructor(private _storeService: StoresDataService) {
    this._initRangesConfig(this);
  }

  /**
   * Update markerPositions by getting the value of apiCallObservable.
   *
   * This higher order function works on different api calls since they all share lat and lng in their data.
   *
   * FIXME: If the call is for stores, also update the this.currentStores (not clean)
   */
  updateMarkers(
    rangeMappingUnit: RangeConfig<GeoareaDto> | RangeConfig<RegionsDto> | RangeConfig<MapStore> | null
  ): void {
    if (rangeMappingUnit == null) {
      return;
    }
    // @ts-expect-error https://github.com/ReactiveX/rxjs/issues/3388
    rangeMappingUnit.apiObservable.pipe(take(1)).subscribe({
      next: (res: ApiPaginatedResponse<(GeoareaDto | RegionsDto | MapStore)[]>) => {
        const newPositions = res.data.map((data) => {
          if ('id' in data) {
            // FIXME: If the call returns MapStore[], also update the stores list
            this.storesLatlngToStoreCode[`${data.latitude},${data.longitude}`] = data.id;
          }
          return { lat: data.latitude, lng: data.longitude };
        });
        // Update marker positions
        this.currentMapMarkerPositions.set(newPositions);
        // Update marker options
        this.mapMarkerOptions = rangeMappingUnit.markerOptionsTemplate;
      },
    });
  }

  ngOnInit(): void {
    if (!this.rangesConfig?.length) {
      throw new Error('Concurrency error: Mapping ranges have not been initializated.');
    }
    // Init the component with geoarea markers (range 0)
    this.updateMarkers(this.rangesConfig.current);
  }

  /**
   * Update markerPositions if the new zoom is in a different range than the previous one.
   *
   * FIXME: also remove store info card if you are de-zooming and it's not empty.
   */
  zoomChanged(): void {
    const newZoom = this._map.getZoom();
    if (newZoom == undefined || !this.rangesConfig) {
      return;
    }

    if (newZoom < this.rangesConfig.current.minZoomValue) {
      // newZoom is part of the previous range
      const previousRange = this.rangesConfig.previous;
      this.updateMarkers(previousRange);
      // FIXME: more than one thing happening, pretty dirty
      // Remove store card if you are de-zooming
      if (this.currentStore()) {
        this.removeStoreCard();
      }
    } else if (newZoom >= (this.rangesConfig.peek()?.minZoomValue ?? Number.POSITIVE_INFINITY)) {
      // newZoom is part of the next range
      const nextRange = this.rangesConfig?.next;
      this.updateMarkers(nextRange);
    } else {
      // newZoom is in the same range as current range
    }
    this.mapZoom = newZoom;
  }

  private _initRangesConfig(thisMapPage: this) {
    const config: [RangeConfig<GeoareaDto>, RangeConfig<RegionsDto>, RangeConfig<MapStore>] = [
      {
        minZoomValue: 1,
        markerClickFunction: () => {},
        markerOptionsSwapMap: {
          'position.lat': 'latitude',
          'position.lng': 'longitude',
        },
        get apiObservable() {
          return thisMapPage._storeService.getGeoareas(thisMapPage.currentFilter);
        },
        markerOptionsTemplate: {
          draggable: false,
          icon: {
            url: '/assets/images/geoarea-marker.svg',
            scaledSize: new google.maps.Size(75, 75),
          },
        },
      },
      {
        minZoomValue: 4,
        markerClickFunction: () => {},
        markerOptionsTemplate: {
          draggable: false,
          icon: {
            url: '/assets/images/region-marker.svg',
            scaledSize: new google.maps.Size(40, 40),
          },
        },
        markerOptionsSwapMap: {
          'label.text': 'numberOfStores',
          'position.lat': 'latitude',
          'position.lng': 'longitude',
        },
        get apiObservable() {
          return thisMapPage._storeService.getRegions(thisMapPage.currentFilter);
        },
      },
      {
        minZoomValue: 9,
        markerClickFunction: () => {},
        markerOptionsTemplate: {
          draggable: false,
          icon: {
            url: '/assets/images/default-marker.svg',
            scaledSize: new google.maps.Size(30, 30),
          },
        },
        markerOptionsSwapMap: {
          'position.lat': 'latitude',
          'position.lng': 'longitude',
        },
        get apiObservable() {
          return thisMapPage._storeService.getMapStores(0, 50, thisMapPage._map.getCenter(), thisMapPage.currentFilter);
        },
      },
    ];

    this.rangesConfig = new statefulArray(config);
  }

  /**
   * Update this.currentStore to show the store info card.
   */
  showStoreDetails(storeLatLng: google.maps.LatLng | null) {
    this._storeService
      .getStore(this.storesLatlngToStoreCode[`${storeLatLng?.lat()},${storeLatLng?.lng()}`])
      .pipe(take(1))
      .subscribe({
        next: (res: ApiPaginatedResponse<StoreDetails>) => {
          this.currentStore.set(res.data);
        },
      });
  }

  /**
   * Handle clicks on markers.
   */
  handleMarkerClick(event: google.maps.MapMouseEvent): void {
    this.centerMap(event);
    if (this.rangesConfig?.isLast()) {
      console.log('Showing store details');
      this.showStoreDetails(event.latLng);
    } else {
      this.updateZoomToNextRange();
    }
  }

  /**
   * Set this.currentStore to null to remove the store info card.
   */
  removeStoreCard() {
    this.currentStore.set(null);
  }

  /**
   * Center the map on the clicked map marker, possibly with a smooth animation.
   */
  centerMap(event: google.maps.MapMouseEvent): void {
    const marker = event.latLng;
    if (marker) {
      this._map.panTo(marker);
    }
  }

  /**
   * Update mapZoom to the min of the next range, to trigger a zoom change.
   */
  updateZoomToNextRange(): void {
    const newZoom = this.rangesConfig?.peek()?.minZoomValue;
    if (newZoom) {
      this.mapZoom = newZoom;
    }
  }
}
