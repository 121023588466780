<div class="-wrapper-map">
  <cdz-store-filter></cdz-store-filter>
  <div class="-map-container">
    <div class="-container-search flex items-center justify-between gap-5">
      <div class="-search-box flex-1">
        <button class="-search-btn"> <mat-icon>search</mat-icon> </button>
        <input
          class="-search-input"
          type="text"
          placeholder="{{ 'shops.searchLabel' | translate }}" />
      </div>
      <!-- <button
        mat-flat-button
        (click)="openFilterDialog()"
        class="-filter">
        <mat-icon>tune</mat-icon>
      </button> -->
    </div>

    <!-- Map  -->
    <google-map
      #map
      (zoomChanged)="zoomChanged()"
      width="100%"
      height="100%"
      [zoom]="mapZoom"
      [center]="mapCenter"
      [options]="googleMapsOptions">
      <!-- Markers  -->
      <map-marker
        *ngFor="let markerPosition of currentMapMarkerPositions()"
        (mapClick)="handleMarkerClick($event)"
        [position]="markerPosition"
        [options]="mapMarkerOptions" />
    </google-map>
    <cdz-map-store
      *ngIf="currentStore() !== null"
      [store]="currentStore()">
    </cdz-map-store>
  </div>
</div>
