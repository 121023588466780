import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Signal, signal, WritableSignal } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UserReadDto } from '@shared/dto/user.dto';
import { RT_DIALOG_CLOSE_RESULT } from '@shared/enums/rt-dialog-close-result.enum';
import { RtDialogService } from '@shared/services/rt-dialog.service';
import { UserComponent } from '@shared/components/user/user.component';
import { EditUserDialog } from 'src/app/dialogs/edit-user/edit-user.dialog';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UsersService } from 'src/app/services/users.service';
import { TranslateModule } from '@ngx-translate/core';
import { IRtDialogClose } from '@shared/interfaces/i-rt-dialog-close.interface';

@Component({
  selector: 'cdz-users',
  standalone: true,
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
  imports: [
    MatIconModule,
    UserComponent,
    CommonModule,
    MatDialogModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  providers: [RtDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersPage implements OnInit {
  users = signal<UserReadDto[]>([]);
  totalItems = signal<number>(0);
  page: WritableSignal<number> = signal(0);
  pageSize: Signal<number> = signal(25);
  showSpinScrolling: WritableSignal<boolean> = signal(false);

  constructor(private _usersService: UsersService, private _rtDialogService: RtDialogService) {}

  ngOnInit(): void {
    this._usersService.users$.subscribe({
      next: (users: UserReadDto[]) => {
        this.users.mutate((prevUsers) => {
          for (const user of users) {
            prevUsers.push(user);
          }

          return prevUsers;
        });

        this.showSpinScrolling.set(false);
      },
    });

    this._usersService.getUsers(this.page(), this.pageSize());

    // Can't rely on cached totalItems.
    // Getting total items on every api call so that new users are always rendered in real time
    this._usersService.totalItems$.subscribe({
      next: (totalItems: number) => {
        this.totalItems.set(totalItems);
      },
    });
  }

  onScroll() {
    if (this.users().length < this.totalItems()) {
      this.showSpinScrolling.set(true);
      this.page.set(this.page() + 1);
      this._usersService.getUsers(this.page(), this.pageSize(), true);
    }
  }

  editUserOpenDialog(user: UserReadDto): void {
    this._rtDialogService
      .open(EditUserDialog, { width: '35vw', height: '90vh', position: { right: '2%' }, data: user })
      .subscribe((res: IRtDialogClose<{ updatedUser: UserReadDto }>) => {
        if (res.result == RT_DIALOG_CLOSE_RESULT.CONFIRM) {
          // TODO: test me

          // If the post request was successful (dialog closes with confirm), then
          // the backend received the update and you can use local data to show the updatedUser,
          // Without calling the API.
          const updatedUser = res.data?.updatedUser;
          if (updatedUser) {
            this.users.set(this.users().map((user: UserReadDto) => (user.id == updatedUser.id ? updatedUser : user)));
          }

          // FIXME: remove me after making sure things work
          // this._usersService.getUsers(this.page(), this.pageSize());
        }
      });
  }
}
