<div
  *ngIf="loadingSvc.isLoadingBlocking$ | async"
  class="-loader">
  <mat-spinner
    [diameter]="70"
    [strokeWidth]="2"
    color="primary"></mat-spinner>
</div>

<div
  class="-extra-layer"
  *ngIf="loadingSvc.isLoadingBlocking$ | async"></div>
