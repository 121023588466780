<div class="card">
  <!-- <div>
    flex box con immagini
  </div> -->
  <div class="flex">
    <!-- Flex with h3 auto margin to push the others -->
    <h3 class="push">{{ store?.name | titlecase }}</h3>
    <img
      src="/assets/images/yellow-chip.svg"
      alt="" />
    <img
      src="/assets/images/purple-chip.svg"
      alt="" />
  </div>
  <p>{{ store?.description | titlecase }}</p>
  <p
    >{{ store?.country | titlecase }} - {{ store?.region | titlecase }} - {{ store?.address | titlecase }},
    {{ store?.city | titlecase }}</p
  >
  <button class="big-button">Vedi dettagli</button>
</div>

