import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class UserAuthGuard {
  constructor(private _oauthService: OAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasIdToken = this._oauthService.hasValidIdToken();
    const hasAccessToken = this._oauthService.hasValidAccessToken();

    return hasIdToken && hasAccessToken;
  }
}

