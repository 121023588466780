import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { UserReadDto, UserUpdateDto } from '@shared/dto/user.dto';
import { ApiPaginatedResponse, ApiResponse } from '@shared/dto/base-res.dto';

@Injectable({ providedIn: 'root' })
export class UsersDataService {
  protected currentUrl: string = environment.apiUri + 'profiles';

  constructor(private _http: HttpClient) {}

  getMe(): Observable<ApiResponse<UserReadDto>> {
    return this._http.get<ApiResponse<UserReadDto>>(this.currentUrl + '/me');
  }

  getAll(page: number, pageSize: number): Observable<ApiPaginatedResponse<UserReadDto[]>> {
    const params = new HttpParams().set('pageSize', pageSize).set('page', page);
    return this._http.get<ApiPaginatedResponse<UserReadDto[]>>(this.currentUrl, { params });
  }

  updateProfile(id: number, body: UserUpdateDto): Observable<ApiResponse<UserReadDto>> {
    return this._http.patch<ApiResponse<UserReadDto>>(this.currentUrl + '/' + id, body);
  }
}
