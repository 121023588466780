<div class="-container-settings">
  <div class="-container-title py-9 px-3">
    <div class="-title headline-5"> {{ 'users.title' | translate }} </div>
  </div>
  <div
    class="-container-users"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    (scrolled)="onScroll()">
    <ng-container *ngIf="users().length; else noUser">
      <cdz-user
        *ngFor="let user of users()"
        (editUser)="editUserOpenDialog($event)"
        [user]="user"></cdz-user>
    </ng-container>
    <ng-template #noUser>
      <p class="-no-user caption py-5 px-3">{{ 'users.noUser' | translate }}</p>
    </ng-template>
  </div>
  <p
    *ngIf="showSpinScrolling()"
    class="py-5">
    <mat-spinner
      class="m-auto"
      [diameter]="40"
      [strokeWidth]="2"
      color="primary"></mat-spinner>
  </p>
</div>
