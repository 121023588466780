import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly _LANG_PREF: string = 'LANG_PREF';
  private readonly _VIEW_PREF: string = 'VIEW_PREF';

  saveLangPref(lang: string) {
    sessionStorage.removeItem(this._LANG_PREF);
    sessionStorage.setItem(this._LANG_PREF, JSON.stringify(lang));
  }

  getLangPref(): string | null {
    const lang = sessionStorage.getItem(this._LANG_PREF);
    if (lang) {
      return JSON.parse(lang);
    }

    return null;
  }

  saveViewPref(view: string) {
    sessionStorage.removeItem(this._VIEW_PREF);
    sessionStorage.setItem(this._VIEW_PREF, JSON.stringify(view));
  }

  getViewPref(): string | null {
    const view = sessionStorage.getItem(this._VIEW_PREF);
    if (view) {
      return JSON.parse(view);
    }

    return null;
  }
}
