import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { UserReadDto } from '@shared/dto/user.dto';

@Component({
  selector: 'cdz-user',
  standalone: true,
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  imports: [CommonModule, MatIconModule, RouterModule],
})
export class UserComponent {
  @Input() user?: UserReadDto;
  @Output() editUser = new EventEmitter<UserReadDto>();
}
