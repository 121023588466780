import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Self, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FilterParameter } from '@shared/dto/filter.dto';
import { Observable } from 'rxjs';
import { FilterFormHelper } from 'src/app/helpers/filter-form.helper';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'cdz-store-filter',
  standalone: true,
  templateUrl: './store-filter.component.html',
  styleUrls: ['./store-filter.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatChipsModule,
    MatFormFieldModule,
  ],
})
export class StoreFilterComponent implements OnInit {
  @Input() isOpen?: boolean;

  brands$?: Observable<FilterParameter[]>;
  geoAreas$?: Observable<FilterParameter[]>;
  fileTypes$?: Observable<FilterParameter[]>;

  constructor(
    public formHelper: FilterFormHelper,
    @SkipSelf() private _filtersService: FiltersService,
    private _router: Router,
    private _activetedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._filtersService.init();

    // Get Value
    this.brands$ = this._filtersService.brands$;
    this.geoAreas$ = this._filtersService.geoareas$;
    this.fileTypes$ = this._filtersService.fileTypes$;

    this._saveFilterOnRoute();

    // Patch forms
    this._activetedRoute.queryParams.subscribe((queryParams) => {
      this.formHelper.patchForm(queryParams);
    });
  }

  areaSelected(area: FilterParameter): boolean {
    const formValues = this.formHelper.form.getRawValue().geographicAreas;

    if (!formValues) {
      return false;
    }

    if (Array.isArray(formValues)) {
      return formValues.map((val) => val.toUpperCase()).includes(area.code);
    } else {
      return formValues.toUpperCase().includes(area.code);
    }
  }

  brandSelected(brand: FilterParameter): boolean {
    const formValues = this.formHelper.form.getRawValue().brands;

    if (!formValues) {
      return false;
    }

    if (Array.isArray(formValues)) {
      return formValues.map((val) => val.toUpperCase()).includes(brand.code);
    } else {
      return formValues.toUpperCase().includes(brand.code);
    }
  }

  private _saveFilterOnRoute(): void {
    this._filtersService.currentFilters$.subscribe({
      next: (res) => {
        if (!res) {
          return;
        }

        this._router.createUrlTree([], {
          queryParams: {
            geographicAreas: res?.geographicAreas,
            brands: res?.brands,
          },
          queryParamsHandling: 'merge',
        });
      },
    });
  }
}
