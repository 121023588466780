import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IRtDialogClose } from '../interfaces/i-rt-dialog-close.interface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class RtDialogService {
  constructor(private _matDialogSvc: MatDialog, private breakpointObserver: BreakpointObserver) {}

  /**
   * default width is 400px
   *
   * @param component that is used to display the dialog
   * @param config MatDialogConfig
   */
  open<TInput = any, TReturn = any>(
    component: ComponentType<any> | TemplateRef<any>,
    config: MatDialogConfig<TInput> = {}
  ): Observable<IRtDialogClose<TReturn>> {
    // set default width to 450px
    config.width = config?.width ?? '400px';

    config.maxWidth = '100%';

    // disable close dialog by click out or clicking esc
    config.disableClose = config?.disableClose ?? true;

    // overwrite/write title inside config.data
    // put data inside input to be conform with TInput interface
    const data: { input: TInput | null | undefined } = {
      input: config.data,
    };

    const dialogRef: MatDialogRef<ComponentType<any> | TemplateRef<any>> = this._matDialogSvc.open(component, {
      ...config,
      width: this.calculateDialogWidth(config.width),
      height: this.calculateDialogHeight(config.height),
      data,
    });

    return dialogRef.afterClosed() as Observable<IRtDialogClose<TReturn>>;
  }

  private calculateDialogWidth(width: string): string {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      return '100vw';
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      return '45vw';
    } else {
      return width;
    }
  }

  private calculateDialogHeight(height?: string): string {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      return '100vh';
    } else {
      return height ?? '800px';
    }
  }
}
