import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreDetails } from '@shared/dto/store.dto';

@Component({
  selector: 'cdz-map-store',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './map-store.component.html',
  styleUrls: ['./map-store.component.scss'],
})
export class MapStoreComponent {
  @Input() store?: StoreDetails | null;
}

/* TODO: FIX CSS
  - [ ] Correct spacing in header and chips
  - [ ] Correct fonts and colors
  - [ ] Correct position also on mobile
*/
// TODO: add method to the button component

