import { Injectable } from '@angular/core';
import { UsersDataService } from '@data-services/users.data-service';
import { ApiPaginatedResponse, ApiResponse } from '@shared/dto/base-res.dto';
import { UserReadDto } from '@shared/dto/user.dto';
import { RtLoadingService } from '@shared/services/rt-loading.service';
import { BehaviorSubject, delay, finalize, Observable, share, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private _currentUser$ = new BehaviorSubject<UserReadDto | null>(null);
  get currentUser$(): Observable<UserReadDto | null> {
    return this._currentUser$.asObservable();
  }

  private _users$ = new Subject<UserReadDto[]>();
  get users$(): Observable<UserReadDto[]> {
    return this._users$.asObservable();
  }

  private _totalItems$ = new BehaviorSubject<number>(0);
  get totalItems$(): Observable<number> {
    return this._totalItems$.asObservable();
  }

  constructor(private _rtLoadingService: RtLoadingService, private _usersDataService: UsersDataService) {}

  getMe(): void {
    this._rtLoadingService.showLoadingBlocking();

    this._usersDataService
      .getMe()
      .pipe(
        tap((res: ApiResponse<UserReadDto>) => this._currentUser$.next(res.data)),
        finalize(() => this._rtLoadingService.hideLoadingBlocking()),
        share()
      )
      .subscribe();
  }

  getUsers(page: number, pageSize: number, isScrolling: boolean = false): void {
    if (!isScrolling) {
      this._rtLoadingService.showLoadingBlocking();
    }

    this._usersDataService
      .getAll(page, pageSize)
      .pipe(
        delay(1000),
        finalize(() => this._rtLoadingService.hideLoadingBlocking())
      )
      .subscribe({
        next: (res: ApiPaginatedResponse<UserReadDto[]>) => {
          this._users$.next(res.data);
          this._totalItems$.next(res.pagination.totalItems || 0);
        },
        error: () => {
          // TODO: Capire come gestire l'errore
        },
      });
  }
}
