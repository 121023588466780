import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ListStore } from '@shared/dto/store.dto';

@Component({
  selector: 'cdz-store',
  standalone: true,
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
  imports: [CommonModule, RouterModule],
})
export class StoreComponent {
  @Input() store?: ListStore;
  @Input() isGridView?: boolean;
}
