<cdz-dialog-template
  [isHeaderEnabled]="false"
  [isLoading]="isLoading"
  [isError]="hasErrors">
  <div body>
    <div class="-container-user p-5 pb-0">
      <div (click)="cancel()">
        <mat-icon>arrow_back</mat-icon>
      </div>
      <div class="-name headline-5"> {{ user?.firstName }} {{ user?.lastName }} </div>
      <!-- <div class="-role caption"> {{ user?.companyRole }} </div> -->
      <div class="-container-chips mt-3.5">
        <div class="-chip caption"> {{ user?.role | titlecase }} </div>
        <div
          class="-chip caption"
          *ngFor="let area of user?.permissions?.geographicAreas">
          {{ area | titlecase }}
        </div>
      </div>
      <div class="-container-img mt-3.5 flex items-center justify-center rounded-xl">
        <!-- <img
          class="rounded-xl"
          *ngIf="user?.image; else noImg"
          [src]="user?.image"
          alt="utente" /> -->
        <!-- <ng-template #noImg> -->
        <div class="headline-5"> {{ user?.firstName | slice:0:1 }} {{ user?.lastName | slice:0:1 }} </div>
        <!-- </ng-template> -->
      </div>
      <form
        [formGroup]="formHelper.form"
        class="mt-5">
        <div class="-title body-2"> {{ 'userDialog.user' | translate }} </div>
        <mat-form-field
          appearance="outline"
          class="w-full">
          <mat-select
            [compareWith]="formHelper.compareFn"
            formControlName="role">
            <mat-option value="ADMIN">Admin</mat-option>
            <mat-option value="user">User</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="-title body-2"> {{ 'userDialog.geographicAreas' | translate }} </div>
        <mat-form-field
          appearance="outline"
          class="w-full">
          <mat-select
            multiple
            formControlName="geographicAreas">
            <mat-option
              *ngFor="let area of adminGeoareas$ | async"
              [value]="area.code">
              {{ area.label | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="-title body-2"> Brand </div>
        <mat-form-field
          appearance="outline"
          class="w-full">
          <mat-select
            multiple
            formControlName="brands">
            <mat-option
              *ngFor="let brand of adminBrands$ | async"
              [value]="brand.code">
              {{ brand.label | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="-title body-2"> File </div>
        <mat-form-field
          appearance="outline"
          class="w-full">
          <mat-select
            multiple
            formControlName="filesAllowed">
            <mat-option
              *ngFor="let file of adminFiletypes$ | async"
              [value]="file.code">
              {{ file.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div
    footer
    class="-container-reset-btns flex items-center justify-evenly gap-3"
    *ngIf="showActionBtns">
    <div>
      <button
        type="button"
        class="body-2"
        mat-stroked-button
        [disabled]="isLoading"
        (click)="cancel()">
        {{ 'userDialog.cancel' | translate }}
      </button>
    </div>
    <div>
      <button
        color="primary"
        class="-btn-save body-2 py-3 px-5"
        mat-stroked-button
        [disabled]="isLoading"
        (click)="onSubmit()">
        {{ 'userDialog.save' | translate }}
      </button>
    </div>
  </div>
</cdz-dialog-template>
